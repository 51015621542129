body.print {
    overflow: hidden;

    img.expand,
    button.export {
        display: none !important;
    }

    #print-info {
        display: flex;
        width: 100%;
        text-align: center;
        justify-content: center;
    }

    #cover {
        display: flex;
        width: 100%;
        text-align: center;
        justify-content: center;
    }

    #account-information-cover {
        display: flex;
        border: none;
        width: auto;
        .infos {
            display: none !important;
        }
    }

    .data-card .infoBlock,
    .data-panel .head h2 .hover {
        display: none;
    }

    .brand-tracking-button,
    .performance__bottom_content--button {
        display: none;
    }

    .note {
        display: none;
    }

    #savings div.selector, 
    #rewards-rebates div.selector {
        right: 16px;
    }

    #pdf-loader {
        display: flex;
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background-color: #fff;
        z-index: 9999999;
    }

    #brand-data {
        &.current-print-0 {
            .rebate-box-separator {
                display: none !important;
            }
        }

        &.current-print {
            border-top: none !important;
            border-radius: 0px !important;
            border-bottom: none !important;

            &.last {
                border-bottom: 1px solid #DCE0EB !important;
                border-radius: 10px !important;
            }

            .head {
                display: none !important;
            }

            .expanded {
                border-top: none !important;
            }

            .rebate-box-separator {
                display: inherit;
            }

            .actions {
                display: none !important;
            }

            .data-row {
                display: none !important;
            }

            .brand-rebate-box {
                border-top: none !important;
            }

            .rebate-box-separator .brand-rebate-box {
                display: none !important;
            
                &.current-rebate-box {
                    display: flex !important   
                }
            }
        }

        &[data-brand] {
            display: flex !important;
            visibility: initial;
            height: auto;
            position: relative;

            .dx-dropdowneditor-icon {
                display: none;
            }

            .brand-selection {
                border: none !important;
            }
        }
    }

    .printable {
        display: block;
    }

    .learn-more-button,
    .brand-tracking-button {
        display: none !important;
    }

    #vsdata {
        &.no-head {
            .head {
                display: none;
            }
        }

        &.no-bottom-border {
            border-bottom: none;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        &.no-top-border {
            border-top: none;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            
            .expandable {
                border-top: none;
            }
        }

        &.print-quarter {
            .semester, .annual {
                display: none;
            }
        }

        &.print-semester {
            .quarter, .annual {
                display: none;
            }
        }

        &.print-annual {
            .semester, .quarter {
                display: none;
            }
        }
    }
}